import React from 'react'
import animationData from './Animation.json'
import Lottie from 'react-lottie'

function AnimationDot() {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

  return (
    <div>
        <Lottie options={defaultOptions}
              height={1500}
              width={1500}
              />
    </div>
  )
}

export default AnimationDot