import React from "react";
import styles from "./Feed.module.css";
import CustomerReview1 from "./assets/Videos/Customer-Review-1.mp4";
import CustomerReview2 from "./assets/Videos/Customer-Review-2.mp4";
import CustomerReview3 from "./assets/Videos/Customer-Review-3.mp4";
import ExpertReview1 from "./assets/Videos/Expert-Review-1.mp4";
import ExpertReview2 from "./assets/Videos/Expert-Review-2.mp4";
import ExpertReview3 from "./assets/Videos/Expert-Review-3.mp4";
import Guide1 from "./assets/Videos/Guide-1.mp4";
import Guide2 from "./assets/Videos/Guide-2.mp4";
import Guide3 from "./assets/Videos/Guide-3.mp4";
import Unboxing1 from "./assets/Videos/Unboxing-1.mp4";
import Unboxing2 from "./assets/Videos/Unboxing-2.mp4";
import Unboxing3 from "./assets/Videos/Unboxing-3.mp4";
import { ReactComponent as Sound } from "./assets/Sound.svg";
import { ReactComponent as Cross } from "./assets/cross.svg";
import { Link } from "react-router-dom";

const Feed = () => {
  const category = [
    "Book Demo",
    "Expert Review",
    "Product Guide",
    "Customer Review",
    "Product Story",
  ];
  const Videos = [
    { source: CustomerReview1, label: "Customer Review" },
    { source: Guide3, label: "Product Guide" },
    { source: ExpertReview1, label: "Expert Review" },
    { source: Unboxing2, label: "Unboxing" },
    { source: CustomerReview2, label: "Customer Review" },
    { source: ExpertReview3, label: "Expert Review" },
    { source: Guide1, label: "Product Guide" },
    { source: Unboxing3, label: "Unboxing" },
    { source: Guide2, label: "Product Guide" },
    { source: CustomerReview3, label: "Customer Review" },
    { source: Unboxing1, label: "Unboxing" },
    { source: ExpertReview2, label: "Expert Review" },
  ];
  return (
    <div className={styles.feed_section}>
      <div className={styles.feed_subsection}>
        <h2>Connect with your customers like never before</h2>
        <p>
          Showcase your Product and Brand by categorizing videos into various
          use cases that your customers actually look for before buying
        </p>
        <div className={styles.category_section}>
          {category.map((category: any) => (
            <div className={styles.category}>{category}</div>
          ))}
        </div>
        <div className={styles.feed_videos}>
          {Videos.map((video) => (
            <div className={styles.video_feed}>
              <div className={styles.icon_section}>
                <p>{video.label}</p>
                <div className={styles.icons}>
                  <Sound className={styles.sound_icon} />
                  <Cross className={styles.cross_icon} />
                </div>
              </div>
              <video src={video.source} loop autoPlay playsInline muted />
              <div className={styles.join_section}>
                <Link to="/bookdemo">
                  <button>Book Demo</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feed;
