import React from 'react'
import Animation from "../Animations/Animation";
import styles from "./Form.module.css";


const Thank = () =>{
   return(
        <div className={styles.thankyou}>
            <Animation/>
            <h1>Thank you</h1>
        </div>
   )
}


export default Thank