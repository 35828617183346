import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Brands from "./components/Brands/Brands";
import Feed from "./components/Feed/Feed";
import Investor from "./components/Investor/Investor";
import Footer from "./components/Footer/Footer";

function App() {
  

  return (
    <div>
      <Header />
      <Home />
      <About />
      <Brands />
      <Feed />
      <Investor />
      <Footer />
    </div>
  );
}
export default App;
