import React from "react";
import styles from "./Investor.module.css";
import { ReactComponent as Linkedin } from "./assets/Linkedin.svg";

const Investorcard = ({ Investor }: { Investor: any }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_section}>
        <div className={styles.image}>
          <img src={Investor.image} alt="" />
        </div>
        <h3>{Investor.name}</h3>
        <div className={styles.details}>
          {Investor.currentcompany ? (
            <>
              <p>{Investor.currentcompany.designation}</p>
              <div className={styles.company}>
                <img src={Investor.currentcompany.company} alt="" />
              </div>
            </>
          ) : null}
        </div>
        {Investor.previouscompany ? (
          <p>{Investor.previouscompany.designation}</p>
        ) : (
          <div className={styles.emptyblock}></div>
        )}
        <div className={styles.card_footer}>
          <div className={styles.company_section}>
            {Investor.previouscompany && (
              <div className={styles.excompany}>
                <img src={Investor.previouscompany.company} alt="" />
              </div>
            )}
          </div>
          <div className={styles.link}>
            <div className={styles.linkedin}>
              <Linkedin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investorcard;
