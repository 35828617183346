import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/App";
import InvestorPage from "./components/Investor/InvestorPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from "./components/Form/Form";
import Animation from "./components/Animations/Animation";
import Hubspot from "./components/Form/Hubspot";
import Thank from "./components/Form/Thank";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Investor" element={<InvestorPage />} />
        {/* <Route path="/joinwaitlist" element={<Form />} /> */}
        <Route path="/bookdemo" element={<Hubspot />} />
        <Route path="/ani" element={<Animation />} />
        <Route path="/thankyou" element={<Thank />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
