import React from "react";
import Investorcard from "./Investorcard";
import AnkitNagori from "./assets/Investors/Ankit_Nagori-modified-removebg-preview.webp";
import KunalShah from "./assets/Investors/Kunal Shah-modified.webp";
import SumitGupta from "./assets/Investors/Sumit_Gupta_Coindcx-modified-removebg-preview.webp";
import corporateInvestor from "./assets/Investors/razorpay_harshil_mathur__shashank_kumar-removebg-preview.webp";
import AkshayChaturvedi from "./assets/Investors/Akshay_Chaturvedi_Leverage-modified-removebg-preview (1).webp";
import Amitkumargupta from "./assets/Investors/Amit_Kumar_Gupta-modified-removebg-preview.webp";
import vinitkumarsingh from "./assets/Investors/Vinit_kumar_singh_byju_s-modified-removebg-preview.webp";
import Harivilayath from "./assets/Investors/Hari_Valiyath-modified-removebg-preview.webp";
import Brijesh from "./assets/Investors/Brijesh_Bharadwaj-modified-removebg-preview.webp";
import Hitesh from "./assets/Investors/Hitesh_Rajwani-modified-removebg-preview.webp";
import Bhuvan from "./assets/Investors/bhuvan_gupta-modified-removebg-preview.webp";
import Eatfit from "./assets/Investorlogos/EATFIT SVG.svg";
import cult from "./assets/Investorlogos/Cultfit.svg";
import cred from "./assets/Investorlogos/CRED SVG.svg";
import freecharge from "./assets/Investorlogos/FreeCharge.svg";
import coindcx from "./assets/Investorlogos/CD_Logo.svg";
import razorpay from "./assets/Investorlogos/Razorpay.svg";
import leverage from "./assets/Investorlogos/Leverage Edu.svg";
import Rebel from "./assets/Investorlogos/Rebel.svg";
import byju from "./assets/Investorlogos/Byju_s.svg";
import pixis from "./assets/Investorlogos/Pixis SVG.svg";
import fampay from "./assets/Investorlogos/fampay.svg";
import socialsamosa from "./assets/Investorlogos/Social Samosa.svg";
import ofbusiness from "./assets/Investorlogos/Of Business.svg";
import styles from "./Investor.module.css";
import Header from "../Header/Header";

const InvestorPage = () => {
  const Investors = [
    {
      name: "Ankit Nagori",
      image: AnkitNagori,
      currentcompany: {
        designation: "Founder",
        company: Eatfit,
      },
      previouscompany: {
        designation: "Co-Founder",
        company: cult,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Kunal Shah",
      image: KunalShah,
      currentcompany: {
        designation: "Founder",
        company: cred,
      },
      previouscompany: {
        designation: "Co-Founder",
        company: freecharge,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Sumit Gupta",
      image: SumitGupta,
      currentcompany: {
        designation: "Co-Founder",
        company: coindcx,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Corporate Investor",
      image: corporateInvestor,
      currentcompany: {
        designation: "Founders",
        company: razorpay,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Akshay Chaturvedi",
      image: AkshayChaturvedi,
      currentcompany: {
        designation: "Founder",
        company: leverage,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Amit Kumar Gupta",
      image: Amitkumargupta,
      currentcompany: {
        designation: "CTO",
        company: Rebel,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Vinit Kumar Singh",
      image: vinitkumarsingh,
      currentcompany: {
        designation: "Business Director",
        company: byju,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Hari Valiyath",
      image: Harivilayath,
      currentcompany: {
        designation: "Co-Founder",
        company: pixis,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Brijesh Bharadwaj",
      image: Brijesh,
      currentcompany: {
        designation: "Product Lead",
        company: fampay,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Hitesh Rajwani",
      image: Hitesh,
      currentcompany: {
        designation: "CEO",
        company: socialsamosa,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
    {
      name: "Bhuvan Gupta",
      image: Bhuvan,
      currentcompany: {
        designation: "Co-Founder & CTO",
        company: ofbusiness,
      },
      linkedin: "https://linkedin.com/AnkitNagori",
    },
  ];

  console.log(Investors);
  return (
    <div>
      <Header />
      <div className={styles.investors_section}>
        <h1>Investors who believe in our vision</h1>
        <div className={styles.investor_page}>
          {Investors.map((investor) => (
            <Investorcard Investor={investor} />
          ))}
        </div>
      </div>
      <br></br>
      <div className={styles.credits_footer}>
        <div className={styles.credits}>
        <a href="/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <a href="/" target="_blank" rel="noreferrer">
        &copy;Troopod.in
        </a>
        <a href="mailto:sanket.sonawane@pushstart.in" target="_blank" rel="noreferrer">
        sanket.sonawane@pushstart.in
        </a>
        </div>
      </div>
    </div>
  );
};

export default InvestorPage;
