import React from "react";
import styles from "./About.module.css";
import { ReactComponent as Time } from "./assets/Time.svg";
import { ReactComponent as Speed } from "./assets/Speed.svg";
import { ReactComponent as Head } from "./assets/Head.svg";

const About = () => {
  return (
    <div className={styles.about_section}>
      <div className={styles.about_subsection}>
        <div className={styles.heading_text}>
          <h1>Boost Your Sales with Troopods’ Intelligent Video Platform</h1>
        </div>
        <div className={styles.para_text}>
          <p>
            Increase your Sales conversions effortlessly with a One-click setup,
            No upfront fee, and Our proprietary AI-engine
          </p>
        </div>
        <div className={styles.about_cards}>
          <div className={styles.about_card}>
            <div className={styles.card_section}>
              <div className={styles.rectangle_image_section}>
                <Time />
              </div>
              <h4>Get Started in 2 Minutes</h4>
              <p>
              It just takes 2 mins to integrate and get started with Troopod
              </p>
            </div>
          </div>
          <div className={styles.about_card}>
            <div className={styles.card_section}>
              <div className={styles.rectangle_image_section}>
                <Speed />
              </div>
              <h4>Auto Content Generation</h4>
              <p>
              Troopod's proprietary AI-powered content generation engine takes care of all video requirements
              </p>
            </div>
          </div>
          <div className={styles.about_card}>
            <div className={styles.card_section}>
              <div className={styles.rectangle_image_section}>
                <Head />
              </div>
              <h4>AI-driven Personalisation</h4>
              <p>
              Personalise Videos, CTA & Pricing for every user to deliver optimal shopping experience
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
