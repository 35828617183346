import React from "react";
import styles from "./Header.module.css";
import { ReactComponent as Troopod } from "./assets/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className={styles.header_section}>
      <div className={styles.header_subsection}>
        <div className={styles.logo_section}>
          <Link to="/">
            <Troopod />
          </Link>
        </div>
        <div className={styles.waitlist_button_section}>
          <Link to="/bookdemo">
            <button>Book Demo</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
