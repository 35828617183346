import React from "react";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";
import Animation from "../Animations/Animation";
import HomeVideo from './assets/Homevideo.mp4'

const Home = () => {
  return (
    <div className={styles.home_section}>
      <div className={styles.Animation_section}>
      <Animation margin={'80px'}/>
      </div>
      <div className={styles.home_subsection}>
        <div className={styles.text_section}>
          <div className={styles.texts}>
            <span>Intelligent Shoppable Video Feed that converts</span>
            <p>
              Use Reel-like video feed on your E-commerce store to provide an
              Immersive, Human & New-Age shopping experience to your customers
              and increase conversion rates by upto 100%
            </p>
            <Link to="/bookdemo">
              <button>Book Demo</button>
            </Link>
          </div>
        </div>
        <div className={styles.video_section}>
          <div className={styles.video}>
            <video
              src={HomeVideo}
              loop
              playsInline
              autoPlay
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
