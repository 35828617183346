import React from "react";
import "./Animation.css";

function Animation({ margin }: { margin?: any }) {
  return (
    <div>
      <div style={{marginLeft:margin}} className="box"></div>
      <div style={{marginLeft:margin}} className="box box2"></div>
      <div style={{marginLeft:margin}} className="box box3"></div>
      <div style={{marginLeft:margin}} className="box box4"></div>
      <div style={{marginLeft:margin}} className="box box5"></div>
      <div style={{marginLeft:margin}} className="box box6"></div>
      <div style={{marginLeft:margin}} className="box box7"></div>
      <div style={{marginLeft:margin}} className="box box8"></div>
      <div style={{marginLeft:margin}} className="box box9"></div>
      <div style={{marginLeft:margin}} className="box box10"></div>
    </div>
  );
}

export default Animation;
