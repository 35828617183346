import React, { useEffect } from "react";
import styles from "./Form.module.css";

const Hubspot = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: "na1",
          portalId: "24133158",
          formId: "75306860-021e-430b-9b0e-bb1d10173b24",
          target: "#hubspotForm"
        });
      }
    });
  }, []);

  return (
    <div className={styles.hubspotcontainer}>
    <div className={styles.hubspotform}>
      <div id="hubspotForm"></div>
    </div>
    </div>
  );
};

export default Hubspot;
